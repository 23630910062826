<template>
  <div class="ctooltip" v-if="help_text!=''"><div v-html="text"></div>
    <div :class="'tooltiptext tooltip-'+align" :style="'width:'+(wd[key]==undefined ?100:wd[key])+'px !important'"><div v-html="help_text"></div></div>
  </div>
</template>
<script>
export default {
  name: 'Ctooltip',
  props: {
   
      text: {
        type: String,
        default: ''
      },
      key: {
        type: String,
        default: 'key0'
      },
      align:{
        type: String,
        default:'top',
      },
      help_text: {
        type: String,
        default: '',
      },
 
  },
   data() {
    return {
      wd:[],
    }
  },
 
  mounted() {
    this.wd[this.key]=40+10*this.help_text.length;
  },
  watch: {
    help_text(newjsonObj) {
      this.wd[this.key]=40+10*newjsonObj.length;
      console.log(this.wd);
    }

  }
}
</script>
<style  lang="scss" scoped>
.ctooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted #ccc;
    cursor: help;
    color: #000;
}
.ctooltip .tooltiptext {
    visibility: hidden;
    position: absolute;
    max-width: 300px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px;
    border-radius: 6px;
    z-index: 9991;
    opacity: 0;
    transition: opacity .6s;
}
.ctooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}
.ctooltip .tooltiptext2 {
    visibility: hidden;
    position: absolute;
    max-width: 300px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    z-index: 1;
}
.tooltip:hover .tooltiptext2 {
    visibility: visible;
}
.tooltip-right {
  top: -5px;
  left: 125%;  
}
.tooltip-right2 {
  top: -5px;
  left: 105%;  
}

.tooltip-right::after {
    content: "";
    position: absolute;
    top: 20px;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #555 transparent transparent;
}

.tooltip-bottom {
  top: 135%;
  left: 50%;  
  margin-left: -150px;
}

.tooltip-bottom2 {
  top: 125%;
  left: 50%;  
  margin-left: -150px;
}

.tooltip-bottom::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #555 transparent;
}

.tooltip-top {
  bottom: 125%;
  left: 50%;  
  margin-left: -150px;
}
.tooltip-top2 {
  bottom: 115%;
  left: 50%;  
  margin-left: -150px;
}

.tooltip-top::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.tooltip-left {
  top: -5px;
  bottom:auto;
  right: 128%;  
}

.tooltip-left2 {
  top: -5px;
  bottom:auto;
  right: 105%;  
}

.tooltip-left::after {
    content: "";
    position: absolute;
    top:20px;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent #555;
}

.ctooltip .tooltiptext-bottomarrow {
    visibility: hidden;
    max-width: 300px;
    background-color: #111;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 130%;
    left: 50%;
    margin-left: -150px;
}
.ctooltip .tooltiptext-bottomarrow::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}
.tooltip:hover .tooltiptext-bottomarrow {
    visibility: visible;
}

.ctooltip .tooltiptext-toparrow {
    visibility: hidden;
    max-width: 300px;
    background-color: #111;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 150%;
    left: 50%;
    margin-left: -150px;
}
.ctooltip .tooltiptext-toparrow::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
}
.tooltip:hover .tooltiptext-toparrow {
    visibility: visible;
}

.ctooltip .tooltiptext-leftarrow {
    visibility: hidden;
    max-width: 300px;
    background-color: #111;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: -5px;
    left: 110%;
}
.ctooltip .tooltiptext-leftarrow::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent black transparent transparent;
}
.tooltip:hover .tooltiptext-leftarrow {
    visibility: visible;
}
.ctooltip .tooltiptext-rightarrow {
    visibility: hidden;
    max-width: 300px;
    background-color: #111;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: -5px;
    right: 110%;
}
.ctooltip .tooltiptext-rightarrow::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent black;
}
.tooltip:hover .tooltiptext-rightarrow {
    visibility: visible;
}
</style>
