import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import axios from 'axios';
import VueApexCharts from 'vue3-apexcharts'
import Ctooltip from '@/components/Ctooltip';
import 'intro.js/introjs.css';


import ElementPlus from 'element-plus';
import JsonViewer from "vue3-json-viewer"

axios.interceptors.request.use( 
  config => { 
       
      if(( config.method=='delete' || config.method=='put' || config.method=='post') && config.headers['Content-Type']!="multipart/form-data" && config.headers['Content-Type']!="application/x-www-form-urlencoded"){ 
          config.data = { 
              ...config.data, 
              id_token:localStorage.getItem('id_token'), 
          } 
      }else if(config.method=='get'){ 
          config.params = { 
              id_token:localStorage.getItem('id_token'), 
              ...config.params 
          } 
      }
      return config 
  },function(error){ 
      return Promise.reject(error) 
  } 
) 

import './assets/libs/admin-resources/rwd-table/rwd-table.min.css'
import './assets/libs/selectize/css/selectize.bootstrap3.css'
import './assets/css/bootstrap.min.css'
import './assets/css/app.min.css'
import './assets/css/icons.min.css'
import 'element-plus/lib/theme-chalk/index.css'
import './assets/css/ctooltip.css'

//if (document.cookie.split(';').some((item) => item.includes('name='))) {
  const app = createApp(App)
  app.use(ElementPlus)
  app.use(JsonViewer)
  axios.defaults.baseURL = process.env.VUE_APP_API_URL_BASE
  axios.defaults.withCredentials = true
  var language_data,language_help_data;
  var initlan=async function init_language(){
    var upd=localStorage.getItem('lan_updated_at');
    upd=upd==undefined || upd=='undefined' || upd==null || upd=='' ?'2021-01-01':upd;
    var response = await axios.get('/v1/getLanguage',{params:{updated_at:upd,curl_lan:localStorage.getItem('curl_lan','jp'), id_token:'eyJraWQiOiJtN1wvZUdtcnZ4QWVURjZGcTF4STI2Z0pIYUJ5WVFrcFZkRlhoc054M2xrTT0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiIxOWUwYWQwNC0zMmU2LTQ3YzAtODYxNi0zMmE5NmNiNDZiYjMiLCJjb2duaXRvOmdyb3VwcyI6WyJhZG1pbiJdLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiY29nbml0bzpwcmVmZXJyZWRfcm9sZSI6ImFybjphd3M6aWFtOjo1MjE3ODcxOTczODI6cm9sZVwvY2JyZWRhdGFfYWRtaW4iLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAuYXAtbm9ydGhlYXN0LTEuYW1hem9uYXdzLmNvbVwvYXAtbm9ydGhlYXN0LTFfUEJvcVlQbnlzIiwiY29nbml0bzp1c2VybmFtZSI6IjE5ZTBhZDA0LTMyZTYtNDdjMC04NjE2LTMyYTk2Y2I0NmJiMyIsImNvZ25pdG86cm9sZXMiOlsiYXJuOmF3czppYW06OjUyMTc4NzE5NzM4Mjpyb2xlXC9jYnJlZGF0YV9hZG1pbiJdLCJhdWQiOiI1Mjc5MG1pY2kyNnNhN3RiN2JwaWM1ZGMzOSIsInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNjU2OTAzMzEwLCJleHAiOjE2NTY5MDY5MTAsImlhdCI6MTY1NjkwMzMxMCwiZW1haWwiOiJhZG1pbkBjYnJlZGF0YS5jb20ifQ.YBXRp922A08zQ71bdt_y_DUWS51S4HclqvxnmwsB7r-8hKPWrVEnY_odzh7lFieoRnKwqdW38mM0ehM3fUlKt7tB2mP0PVxeV_INvYDJtcnjgGubwqT33fpb-kqHUfEp742wamGOXRFbxQiuMrwoTcASDR5GJrAFB6kZvT_9k6VII4BhXgPbkCDg3WZHL9slV8XU90U_U7lkTDqoDOqHzMxPkV8QC1w3fygO0HW5-SQF3ZC663nG40NmhVuotsRFxMhFGxxf4Cp0rIejuLLeG9QqfxBBsGY0LgurVicm094myATD8NoG1C5-V4Gm-a_jO-Orr1FPdN6nNXLYF123123'} })
    if(upd<response.data.last_updated_at || localStorage.getItem('language_data')==null || localStorage.getItem('language_data')==''){
       
        localStorage.setItem('lan_updated_at',response.data.last_updated_at); 
        let lan_data=JSON.stringify(response.data.data) 
        let lan_help_data=JSON.stringify(response.data.help_data) 
        localStorage.setItem('language_data',lan_data);
        localStorage.setItem('language_help_data',lan_help_data);

        language_data=response.data.data;
        language_help_data=response.data.help_data;
    }else{
       
            language_data=JSON.parse(localStorage.getItem('language_data'));
            language_help_data=JSON.parse(localStorage.getItem('language_help_data'));
   
    }
    // 配置全局变量 页面中使用 inject 接收
    app.provide('global',{
        language_data,
        language_help_data
    })
  
    app.use(router).use(VueApexCharts).use(Ctooltip).mount('#wrapper')
}
initlan();
//}